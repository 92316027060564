// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';  // Add this import
import PromptManagement from '../components/PromptManagement';
import SchemaManagement from '../components/SchemaManagement';
import AgentManagement from '../components/AgentManagement';

const AdminDashboard = () => {
  const { isAdmin } = useAuth();
  const [activeSection, setActiveSection] = useState('prompts');

  useEffect(() => {
    console.log('AdminDashboard auth state:', { isAdmin });
  }, [isAdmin]);

  const renderContent = () => {
    switch (activeSection) {
      case 'prompts':
        return <PromptManagement />;
      case 'schema':
        return <SchemaManagement />;
      case 'agents':
        return <AgentManagement />;
      default:
        return <PromptManagement />;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="container mx-auto px-2 sm:px-0 py-2 sm:py-4">
        <div className="mb-6">
          <nav className="flex space-x-6 border-b border-gray-200">
            <button 
              className={`pb-4 px-2 -mb-px ${
                activeSection === 'prompts' 
                  ? 'border-b-2 border-primary text-primary font-medium'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveSection('prompts')}
            >
              Prompt
            </button>
            <button 
              className={`pb-4 px-2 -mb-px ${
                activeSection === 'schema' 
                  ? 'border-b-2 border-primary text-primary font-medium'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveSection('schema')}
            >
              Schema
            </button>
            <button 
              className={`pb-4 px-2 -mb-px ${
                activeSection === 'agents' 
                  ? 'border-b-2 border-primary text-primary font-medium'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveSection('agents')}
            >
              Agents
            </button>
          </nav>
        </div>
        
        {/* Main content area */}
        <div className="w-full">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;